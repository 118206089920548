import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Injectable({
  providedIn: 'root',
})
export class IsLoadingService {
  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay) {}

  // Mostrar el spinner
  show() {
    if (!this.overlayRef) {
      // Si el overlay no existe, lo creamos
      this.overlayRef = this.overlay.create({
        hasBackdrop: true, // Creamos un fondo oscuro para el spinner
        backdropClass: 'cdk-overlay-dark-backdrop',
      });

      // Creamos una instancia del componente Spinner y lo adjuntamos al overlay
      const spinnerPortal = new ComponentPortal(SpinnerComponent);
      this.overlayRef.attach(spinnerPortal);
    }
  }

  // Ocultar el spinner
  hide() {
    if (this.overlayRef) {
      this.overlayRef.dispose(); // Eliminamos el overlay y su contenido
      this.overlayRef = null;
    }
  }
}
