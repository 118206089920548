import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { IsLoadingService } from '../../shared/services/isLoading.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0; // Contador de solicitudes activas

  constructor(private isLoadingService: IsLoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Activamos el spinner cuando se realiza una solicitud HTTP
    this.activeRequests++; // Aumentamos el contador de solicitudes activas
    if (this.activeRequests === 1) {
      // Muestra el spinner solo cuando la primera solicitud comienza
      this.isLoadingService.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--; // Disminuimos el contador al terminar cada solicitud
        if (this.activeRequests === 0) {
          this.isLoadingService.hide(); // Ocultamos el spinner cuando todas las solicitudes han terminado
        }
      })
    );
  }
}
