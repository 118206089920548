import { Route } from '@angular/router';
import { PortadaPersonalComponent } from './components/portada-personal/portada-personal.component';
import { MitableroComponent } from './components/mi-tablero/mitablero.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'mi-tablero',
    component: MitableroComponent
  }
];
